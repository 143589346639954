import React from 'react';
import Productbox from './Productbox';
import veg1 from '../images/img/veg-1.png';
import veg2 from '../images/img/veg-2.png';
import nonveg from '../images/img/non-veg.png';
import addon from '../images/img/add-on.png';

function Products() {
  return (
    <div id='products'>
        <h1>CHOOSE & ENJOY</h1>
        <p>Content goes here</p>
        <div className='a-container'>
            <Productbox image={veg1} title="Veg Biryani"/>
            <Productbox image={veg2} title="Veg Biryani"/>
            <Productbox image={nonveg} title="Non Veg Biryani"/>
            <Productbox image={addon} title="Add Ons"/>
        </div>
    </div>
  )
}

export default Products