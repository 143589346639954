import React from 'react';

function Contact() {
  return (
    <div id='contact'>
        <h1>BOOK YOUR ORDER</h1>
        <form>
            <input type='text' placeholder='Full Name' />
            <input type='text' placeholder='Your Email' />
            <input type='text' placeholder='Mobile Number'/>
            <textarea placholder='Write Here...'></textarea>
            <input type='submit' value='BOOK' id="contactBtn" />
        </form>
    </div>
  )
}

export default Contact