import React from 'react';


function Footer() {
  return (
    <>
        <div id='footer'>
            <p>
            All Rights Reserved. © 2022 Reserved by TGF. 
            </p>
        </div>        
    </>
  )
}

export default Footer