import React from 'react';
import aboutimage from '../images/img/about.png'

function About() {
  return (
    <div id='about'>
        <div className='about-text'>
            <h1>UPCOMING EVENTS</h1>
            <p>Content Here</p>
            <button>Read More</button>
        </div>
        <div className='about-image'>
           <img src={aboutimage} alt='' /> 
        </div>
    </div>
  )
}

export default About