import React from 'react';

function Header() {
  return (
    <div id='main'>
        <div className='header-heading'>
            <h3>A Great Time for A Good Taste of The Good Food</h3>
            <h1>THE <span>BIRYANI</span><br/> EXCHANGE</h1>
            <p className='details'>Awesome Food at Awesome prices</p>
            <div className='header-btns'>
                <a href='/' className='header-btn'>Order</a>
            </div>
        </div>
    </div>
  )
}

export default Header