import React from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import About from './Components/About';
import Products from './Components/Products';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

function App() {

  return (
    <div className="App">
      <>
      <Navbar/>
      <Header/>
      <Products/>
      <About/> 
      <Contact/>
      <Footer/>
      </>    
    </div>
  );
}

export default App;
